import React from 'react';
import { useParams } from '@thd-olt-component-react/router';
import { getPipMandiViewModel } from '../../api/index';
import { useGccPipApp } from '../../hooks/useGccPipApp';

export const PipMandi = () => {
  const { itemId } = useParams();
  const { component } = useGccPipApp(itemId, getPipMandiViewModel);

  return <>{component}</>;
};

PipMandi.propTypes = {
};

PipMandi.displayName = 'PipMandi';

PipMandi.defaultProps = {
  name: '',
};
