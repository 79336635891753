/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Error as ErrorPage } from '../pages/Error';
import { setPipModel } from '../services/index';
import { ddoInit, setOnLoadPageError } from '../utility/analytics';

export const useGccPipApp = (id, getPipModel) => {
  const [component, setComponent] = useState(null);

  const getGccPipApp = async () => {
    if (!id) {
      console.error('Id is not found');
      return <ErrorPage error={new Error('Id is not found')} debug />;
    }

    try {
      const pipModelResponse = await getPipModel(id);
      const {
        redirectToActionParameters,
        httpNotFoundResultMessage,
      } = pipModelResponse;

      if (redirectToActionParameters || httpNotFoundResultMessage) {
        setOnLoadPageError('system', 'Pip Model API', `No product found: ${id}`, 'PIPModelAPI No product found');
        ddoInit();
        const comp = await import(/* webpackChunkName: "notAvailable" */ '../pages/pip/NotAvailable');
        return <comp.NotAvailable name="product-not-available" />;
      }

      setPipModel(pipModelResponse);
      const comp = await import(/* webpackChunkName: "pip" */ '../pages/pip/PipApp');
      return (<comp.PipApp name="gcc-thd-pip" />);

    } catch (err) {
      console.log('Error ==> ', err);
      const { isDebugMode } = window?.__EXPERIENCE_CONTEXT__;
      setOnLoadPageError('system', 'Pip Model API', `ERROR: ${err}`, 'PIPModelAPI API Call Error');
      ddoInit();
      return (<ErrorPage error={err} debug={isDebugMode} />);
    }
  };

  useEffect(() => {
    const fetchPipApp = async () => {
      const comp = await getGccPipApp(id, getPipModel);
      setComponent(comp);
    };
    fetchPipApp();
  }, []);

  return { component };
};
