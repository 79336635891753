import axios from 'axios';
import { appendAutobahnUrl } from '../utility/customMeasureAndInstallExpConfiguration';

export const getPipViewModel = (omsId) => axios({
  url: appendAutobahnUrl(`/api/pipmodel/item/${omsId}`),
  crossDomain: true,
  method: 'GET'
}).then((result) => result.data);

export const getPipEstimateViewModel = (id) => axios({
  url: appendAutobahnUrl(`/api/pipmodel/estimate/${id}`),
  crossDomain: true,
  method: 'GET'
}).then((result) => result.data);

export const getPipMandiViewModel = (id) => axios({
  url: appendAutobahnUrl(`/api/pipmodel/mandi/${id}`),
  crossDomain: true,
  method: 'GET'
}).then((result) => result.data);
