import {
  setWindowDigitalData,
  setWindowPageModel,
  setEnsightenData,
  setWindowGlobalConfig,
} from '../utility';
import { analyticsInit, ensightenInit } from '../utility/analytics';

export default (result) => {
  const { model, digitalData, ensightenData } = result;
  setWindowPageModel(model);
  setWindowDigitalData(digitalData);
  setEnsightenData(ensightenData);
  setWindowGlobalConfig();
  analyticsInit();
  ensightenInit();
};
